import React, { useEffect, useState } from "react";
import { GiCancel } from "react-icons/gi";
import Items from "./Items";
import { Card, Form } from "react-bootstrap";
import { AiOutlineSend } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  removeChat,
  saveAllMessage,
  saveMessage,
} from "../../Redux/slices/chatSlice";
import Pusher from "pusher-js";
import BaseUrl from "../../BaseUrl";

const ChatSection = ({
  handleCloseChat,
  handleSendMessage,
  handleStatus,
  isFetching,
  getLocation,
  email,
  setEmail,
  name,
  setName,
  message,
  setMessage,
  showEmailBox,
}) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state?.chat?.items);
  const chatId = useSelector((state) => state?.chat?.chatId);

  const getAllMessages = () => {
    const id = chatId;
    const form = new FormData();
    form.append("id", parseInt(id));

    BaseUrl.post(`web_chat_message`, form)
      .then((res) => {
        if (res?.data?.status == "successfull") {
          console.log("res=sha", res?.data);
          dispatch(saveAllMessage(res?.data?.data));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    //     getAllMessages();
    const pusher = new Pusher("e4ee6f0dc3f7eecb2c71", {
      cluster: "ap2",
    });
    const channel1 = pusher.subscribe("channel1");

    channel1.bind("event1", function (data) {
      console.log("data", data);
      if (!data?.ip_address && data?.status !== "seen") {
        // setitems((e) => [data, ...e]);
        console.log("data======================", data);
        dispatch(saveMessage(data));
      }
    });

    return () => {
      pusher.unsubscribe("my-channel");
    };
  }, []);

  return (
    <>
      <div className="card chatBox" style={{ zIndex: "9999" }}>
        <div className="card-header position-relative bg-theme py-3">
          <div className="position-absolute top-0 start-100 translate-middle">
            <button
              onClick={handleCloseChat}
              className="btn btn-danger rounded-circle d-flex align-items-center justify-content-center p-0"
              style={{ width: "30px", height: "30px" }}
            >
              <GiCancel className="text-white" />
            </button>
          </div>
          <p className="text-center text-light mb-0">
            We are live and ready to chat with you
          </p>
        </div>
        {/* chatId */}
        {showEmailBox ? (
          <>
            <Items items={items} />

            <div className="card-footer bg-transparent">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  message.trim() !== "" && handleSendMessage();
                }}
                className="row align-items-center"
              >
                <div className="col px-1">
                  <input
                    className="form-control border-0 p-2 w-100"
                    placeholder="Write a message..."
                    type="text"
                    // disabled={isFetching}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    onClick={handleStatus}
                  />
                </div>
                <div className="col-auto px-1">
                  <button
                    className="btn border-0"
                    type="submit"
                    disabled={isFetching}
                  >
                    <AiOutlineSend
                      className="cursor-pointer"
                      size={20}
                      role="button"
                      type="submit"
                      disabled={isFetching}
                    />
                  </button>
                </div>
              </Form>
            </div>
          </>
        ) : (
          <>
            <Card>
              <Card.Body>
                <Form onSubmit={getLocation}>
                  Please Enter Your Email
                  <input
                    type="email"
                    className="form-control mb-3"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                  />
                  Please Enter Your Name
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required={true}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={isFetching}
                  >
                    {isFetching ? (
                      <span className="spinner-border spinner-border-sm" />
                    ) : (
                      "Let's Start Chat"
                    )}
                  </button>
                </Form>
              </Card.Body>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default ChatSection;
