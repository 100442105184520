import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../Component/Layout/Layout";

const QuestionAnswer = lazy(() => import("../Pages/QuestionAnswer"));
const QuestionAnswer2 = lazy(() => import("../Pages/QuestionAnswer2"));
const QuestionAnswer3 = lazy(() => import("../Pages/QuestionAnswer3"));
const QuestionAnswer4 = lazy(() => import("../Pages/QuestionAnswer4"));
const Calendli = lazy(() => import("../Pages/Calendli"));
const Calendli2 = lazy(() => import("../Pages/Calendli2"));
const Calendli3 = lazy(() => import("../Pages/Calendli3"));
const Calendli4 = lazy(() => import("../Pages/Calendli4"));
const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
const TermsAndCon = lazy(() =>
  import("../Component/PrivacyPolicy/TermsAndCon")
);
const EmailUnsub = lazy(() => import("../Pages/EmailUnsub"));
const Faq = lazy(() => import("../Pages/Faq"));
const Contact = lazy(() => import("../Pages/Contact"));
const Testimonial = lazy(() => import("../Pages/Testimonial"));
const About = lazy(() => import("../Pages/About"));
const Home = lazy(() => import("../Pages/Home"));
const RefiRenew = lazy(() => import("../Pages/refi-renew"));
const Getaquote = lazy(() => import("../Pages/Getaquote"));

const AppRouting = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/about" element={<About />} />
            <Route path="/question-answer" element={<QuestionAnswer />} />
            <Route path="/question-answer2" element={<QuestionAnswer2 />} />
            <Route path="/question-answer3" element={<QuestionAnswer3 />} />
            <Route path="/question-answer4" element={<QuestionAnswer4 />} />
            <Route path="/calendli" element={<Calendli />} />
            <Route path="/calendli2" element={<Calendli2 />} />
            <Route path="/calendli3" element={<Calendli3 />} />
            <Route path="/calendli4" element={<Calendli4 />} />
            <Route path="/getaquote" element={<Getaquote />} />
            <Route path="/refi-renew" element={<RefiRenew />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/term-and-condition" element={<TermsAndCon />} />
            <Route path="/email-unsubscribe" element={<EmailUnsub />} />
            <Route path="/faq" element={<Faq />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRouting;
