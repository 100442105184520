import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang:'rtl',
  loading: false,
  value: 0,
  activeStep1: 0,
  activeStep2: 0,
  activeStep3: 0,
  activeStep4: 0,
  formOne: {
    new_property: null,
    type_property: null,
    home_cost: null,
    down_payment: null,
    found_property: null,
    name: null,
    email: null,
    phonenumber: null,
    city: null,
    state: null,
    zip: null,
    default_language: null,
    employment_type: null,
    refinancing: null,
    purchase: null,
    preapproval: null,
    num_property: null,
    num_applicants: null,
    staff: null,
  },
  formTwo: {
    property_renewal: null,
    current_property_value: null,
    remaining_mortgage_balance: null,
    name: null,
    email: null,
    phonenumber: null,
    city: null,
    state: null,
    zip: null,
    default_language: null,
    employment_type: null,
    refinancing: null,
    purchase: null,
    preapproval: null,
    num_property: null,
    num_applicants: null,
    staff: null
  },
  formThree: {
    property_refinancing: null,
    current_property_value: null,
    remaining_mortgage_balance: null,
    current_mortgage_lender: null,
    additional_funds: null,
    name: null,
    email: null,
    phonenumber: null,
    city: null,
    state: null,
    zip: null,
    default_language: null,
    employment_type: null,
    refinancing: null,
    purchase: null,
    preapproval: null,
    num_property: null,
    num_applicants: null,
    staff: null
  },
  formFour: {
    live_property: null,
    type_property: null,
    value_property: null,
    down_payment: null,
    name: null,
    email: null,
    phonenumber: null,
    city: null,
    state: null,
    zip: null,
    default_language: null,
    employment_type: null,
    refinancing: null,
    purchase: null,
    preapproval: null,
    num_property: null,
    num_applicants: null,
    staff: null,
  },
};

export const setFormOne = createAsyncThunk(
  "posts/setFormOne",
  async (payload) => {
    const { formOne } = payload;
    const options = {
      method: "POST",
      mode: "no-cors",
      headers: new Headers({
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
        "Content-Length": "<calculated when request is sent>",
        Host: "<calculated when request is sent>",
        "User-Agent": "PostmanRuntime/7.30.0",
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      }),
      body: JSON.stringify(formOne),
    };
    let res = await fetch(
      "https://6fb3-101-53-224-126.in.ngrok.io/api/newmortgage",
      options
    );
    return await res.json();
  }
);

export const formSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    next: (state, action) => {
      console.log("action", action.payload);
      let name = action.payload?.type;
      let value = action.payload?.value;
      if (action.payload?.type == "new_property") {
        state.formOne = { ...state.formOne, [name]: value };
      } else if (action.payload?.type == "type_property") {
        state.formOne = { ...state.formOne, [name]: value };
      } else if (action.payload?.type == "home_cost") {
        state.formOne = { ...state.formOne, [name]: value };
      } else if (action.payload?.type == "down_payment") {
        state.formOne = { ...state.formOne, [name]: value };
      } else if (action.payload?.type == "found_property") {
        state.formOne = { ...state.formOne, [name]: value };
      } else if (action.payload?.type == "staff") {
        state.formOne = { ...state.formOne, [name]: value };
      }

      state.activeStep1 += 1;
    },
    next2: (state, action) => {
      console.log("action", action.payload);
      let name = action.payload?.type;
      let value = action.payload?.value;
      if (action.payload?.type == "property_renewal") {
        state.formTwo = { ...state.formTwo, [name]: value };
      } else if (action.payload?.type == "current_property_value") {
        state.formTwo = { ...state.formTwo, [name]: value };
      } else if (action.payload?.type == "remaining_mortgage_balance") {
        state.formTwo = { ...state.formTwo, [name]: value };
      } else if (action.payload?.type == "down_payment") {
        state.formTwo = { ...state.formTwo, [name]: value };
      } else if (action.payload?.type == "renewing_mortgage") {
        state.formTwo = { ...state.formTwo, [name]: value };
      }

      state.activeStep2 += 1;
    },
    next3: (state, action) => {
      console.log("action", action.payload);
      let name = action.payload?.type;
      let value = action.payload?.value;
      if (action.payload?.type == "property_refinancing") {
        state.formThree = { ...state.formThree, [name]: value };
      } else if (action.payload?.type == "current_property_value") {
        state.formThree = { ...state.formThree, [name]: value };
      } else if (action.payload?.type == "remaining_mortgage_balance") {
        state.formThree = { ...state.formThree, [name]: value };
      } else if (action.payload?.type == "current_mortgage_lender") {
        state.formThree = { ...state.formThree, [name]: value };
      } else if (action.payload?.type == "additional_funds") {
        state.formThree = { ...state.formThree, [name]: value };
      }

      state.activeStep3 += 1;
    },
    next4: (state, action) => {
      console.log("action==", action.payload);
      let name = action.payload?.type;
      let value = action.payload?.value;
      if (action.payload?.type == "live_property") {
        state.formFour = { ...state.formFour, [name]: value };
      } else if (action.payload?.type == "type_property") {
        state.formFour = { ...state.formFour, [name]: value };
      } else if (action.payload?.type == "value_property") {
        state.formFour = { ...state.formFour, [name]: value };
      } else if (action.payload?.type == "down_payment") {
        state.formFour = { ...state.formFour, [name]: value };
      }

      state.activeStep4 += 1;
    },
    handleForm: (state, action) => {
      console.log("action", action.payload);
      const name = action.payload?.name;
      const value = action.payload?.value;
      state.formOne = { ...state.formOne, [name]: value };
    },
    handleForm2: (state, action) => {
      console.log("action", action.payload);
      const name = action.payload?.name;
      const value = action.payload?.value;
      state.formTwo = { ...state.formTwo, [name]: value };
    },
    handleForm3: (state, action) => {
      console.log("action", action.payload);
      const name = action.payload?.name;
      const value = action.payload?.value;
      state.formThree = { ...state.formThree, [name]: value };
    },
    handleForm4: (state, action) => {
      console.log("action", action.payload);
      const name = action.payload?.name;
      const value = action.payload?.value;
      state.formFour = { ...state.formFour, [name]: value };
    },
    handlePrevious: (state, action) => {
      if (action.payload == "one") {
        state.activeStep1 -= 1;
      } else if (action.payload == "two") {
        state.activeStep2 -= 1;
      } else if (action.payload == "three") {
        state.activeStep3 -= 1;
      } else if (action.payload == "four") {
        state.activeStep4 -= 1;
      }
    },
    handleDone: (state, action) => {
      if (action.payload == "one") {
        state.activeStep1 = 0;
        state.formOne = {};
      } else if (action.payload == "two") {
        state.activeStep2 = 0;
        state.formTwo = {};
      } else if (action.payload == "three") {
        state.activeStep3 = 0;
        state.formThree = {};
      } else if (action.payload == "four") {
        state.activeStep4 = 0;
        state.formFour = {};
      }
    },

    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    changeDir: (state, action) => {
      state.dir = action.payload;
    },
  },
  extraReducers: {
    [setFormOne.pending]: (state) => {
      state.loading = true;
    },
    [setFormOne.fulfilled]: (state, action) => {
      state.loading = false;
      state.entities = action?.payload;
    },
    [setFormOne.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  next,
  handleForm,
  next2,
  handleForm2,
  next3,
  handleForm3,
  next4,
  handleForm4,
  handlePrevious,
  handleDone,
  decrement,
  incrementByAmount,
} = formSlice.actions;

export default formSlice.reducer;
