import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes   by default
    },
    resources: {
      en: {
        translation: {
          // options
          yes: "Yes",
          no: "No",
          readOurPrivacyPolicy: "Read our privacy policy",

          notFoundProperty:
            "If you have not found a property yet, simply enter the approximate amount you’re looking for.",
          basedOnyourTiminng:
            "Based on your timing, we will guid you to the best next step.",
          filterRates:
            "We are filtering rates based on your exact situation. If you don’t live here, we will assume it’s a rental property.",

          sellYourProperty:
            "☝ If you were to sell your property tomorrow, what fair price would you get for it.",
          smallerMortage:
            "☝ The smaller your mortgage balance is relative to your property value, the better the rates will be. We will calculate this ratio to filter rates presented to you.",
          help: "This will help us define the best timing to secure your rate.",

          fullName: "Full Name",
          email: "Email",
          phone: "Phone",
          city: "City",
          state: "Province",
          zipCode: "Zip Code",
          preferedLanguage: "Prefered Language",
          fewMoreDetails:
            "  We need few more details to get you a perfect rate:",
          mortageRequest: "Mortgage Requested For:",
          refinancing: "Refinancing",
          purchase: "Purchase",
          preapproval: "Preapproval",
          NumberOfProperties: "Number of properties",
          NumberOfApplicants: "Number of applicants",

          continue: "Continue",
          back: "Back",

          houseOrCondo: "House or Condo",
          secondaryHomeOrCottage: "Secondary Home or Cottage",
          duplex: "Duplex",
          triplex: "Triplex",
          fourplex: "Fourplex",
          commercial: "Commercial",

          offerToPurchase: "I have made an offer to purchase",
          offerSoon: "I plan on making an offer soon",
          getPreQualified: "I want to get pre-qualified",
          justCurious: "I'm just curious",

          in1To2Months: "in 1 to 2 months",
          in2To4Months: "in 2 to 4 months",
          inMoreThan4months: "in more than 4 months",

          canada: "Canada",
          england: "England",
          us: "US",
          french: "French",
          english: "English",
          selfEmployment: "Self-employment Not incorporated",
          selfEmploymentIncorporated: "Self-employment incorporated",
          employed: "Employed",
          retired: "Retired",
          floatingEmpType: "Employment Type",
          employmentType: "Employment Type",
          currentEstimatedPropertyValue: "Current estimated property value",
          currentMortgageAmount: "Current mortgage amount",
          endOfCurrentTerm: "End of current term",
          requestedRefinanceAmount: "Requested refinance amount",
          currentFI2: "Current FI",
          IsThePropertyACodoPlexOrHome:
            " Is the property a: Codo, Plex or Home",
          requestedAmount: "Requested amount",
          isThePropertyCondoPlexOrHome: "Is the property condo, plex or home",
          conditionOfFinancing: "Condition of financing",
          preapprovalAmount: "Preapproval amount",
          isThePropertyACondoPlexOrHome:
            "is the property a condo, plex or home",
          maximumWithdraw:
            "Your mortgage balance plus your additional funds cannot be higher than 80% of your property value.The maximum you can withdraw is $7,999,989",

          lowMortageRates: "Tailored mortgage solutions for you ",
          ScheduleanAppointment: "Schedule an Appointment",
          howCanweHelp: "How can we help you with your mortgage?",
          contact: "Contact",
          career: "Career",

          diagramisalaunchpad:
            "I am Rawi Hammal, a mortgage specialist who is dedicated to providing the best possible service to clients all across Canada.",
          withTheirlongTerm:
            "With years of experience in the industry, I have developed a deep understanding of the complexities of the mortgage process, and I am committed to helping my clients navigate the often-confusing landscape of mortgage lending",
          diagramIsBacked:
            "Whether you're a first-time homebuyer, looking to refinance an existing property, or are interested in exploring your mortgage options, I am here to help. With a focus on personalized service and attention to detail, I work with you to understand your unique needs and goals, and develop a customized mortgage solution that meets your needs.",
          portageFinance:
            "I believe that communication is key to a successful mortgage transaction. I will keep you informed every step of the way, providing regular updates and answering any questions you may have.",
          soifyouare:
            "So if you're in need of a mortgage specialist you can trust, look no further. Contact me today to schedule a consultation and start the journey towards achieving your mortgage goals.",

          tellus: "Tell us",
          yourgoal: "your goal",

          inputyour: "Input your",
          propertyinformation: "property information",

          getyour: "Get your",
          bestrate: "best rates",

          newMortgage: "New Mortgage",
          mortgageRenewal: "Mortgage Renewal",
          refinanceMyMortgage: "Refinance My Mortgage",
          preApproval: "Pre Approval",
          scheduleACall: "Schedule A Call",

          ineedapre:
            "I need a pre-qualification, proof of financing for my offer or a new mortgage for the property I am buying.",
          iwanttorenew:
            "I want to renew my mortgage or compare my current lender’s offer with nesto's offer because my mortgage renewal date is coming up.",
          wanttorefinance:
            "I want to refinance to lower my mortgage payments and increase cash flow, consolidate my debts, access my equity to fund an investment or major expense.",
          ineddaprequalification:
            " I need a pre-qualification, proof of financing for my offer or a new mortgage for the property I am buying.",
          testimonial: "Testimonial",
          peopleAreSaying: "People Are Saying",
          submit: "Submit",
          joinrawihammal: "Join Rawi Hammal VIP List",

          yourmortgagesimplifiedSavetime: "Your mortgage simplified.",
          savebig: "Save Big.",
          saveTime: "Save Time.",

          // Contact Start

          letscontact: "Lets-contact",
          contactWithUs: "Contact With Us",
          emergencyContact: "Contact With Us",

          // Contact End

          // Testimonial Start

          JoinCanadiansthat: "Join 210,000+ Canadians* that trusted us.",

          CheckoutRawiHammalreviews:
            "  Check out Rawi Hammal reviews from people who were in your shoes & now have a low rate that works for them.",
          realPeople: "Real people,",
          realReviews: "real reviews",

          BeOneOfThem: "Be One Of Them",

          becomeAhappyRawiHammal: "Become a happy Rawi Hammal customer",

          connevtWith:
            "Connect with 250+ licensed mortgage experts you can trust",

          chatWithUs: "Chat with us today",

          // Testimonial End

          // q1

          liveInThisNewProperty: "Will you live in this new property?",
          typeOfProperty: "What type of property is it?",
          valueInPropety: "How much value in propety is?",
          yourDownPayment: "How much in your down payment?",
          basicDetails: "Basic Details",
          foundAProperty: "Found a property",

          // q2
          followingheRenewal:
            "Will you live in this property following the renewal?",
          propertyValue: "What is your current property value?",
          homeCost: "How much will your home cost?",
          mortgageBalance: "What is your remaining mortgage balance?",
          renewingYourMortgage: "When are you renewing your mortgage? ",
          on: "on",
          reviews: "reviews",
          getrateschangeupdates:
            " Get rates change updates, mortage tips, and expert advice",
          byclickingSignUp:
            "By clicking ‘Sign Up’, you agree to Rawi Hammal's <1>Terms and Conditions</1>. You may unsubscribe at any time.",
          getaQuote: "Get a Quote",

          quickLinks: "Quick Links",
          contactUs: "Contact Us",
          aboutUs: "About Us",
          faq: "Faq",
          testimonialFooter: "Testimonial",
          address: "Address",
          newyork: "88 street new york",
          privacyPolicy: "Privacy Policy",
          privacyChoices: " Privacy Choices",
          whosBehind: "Who’s Behind",

          whychooseRawiHammal: "Why choose Rawi Hammal",
          becauseWeOffer:
            "Because we offer the floor rate from the start. It’s the most important purchase of your life, why should you have to pay a premium for it?",
          yesWemakeThem:
            "Yes, we make less than the average broker or mortgage specialist, but we get the peace of mind knowing that we helped you save thousands of dollars on your mortgage. We feel pretty good about this",
          howRawiHammalWorks: "How Rawi Hammal Works",

          alltheHelpWithouttheCommission:
            "All the Help, Without the Commission.",
          implyputoursalaried:
            "Simply put, our salaried advisors are rewarded based on your satisfaction. We’re here to help you reach your goal and guide you through the complicated world of home financing. #yesyoucan #empowermentisthenewsexy",

          theBestRates: "The Best Rates, Because We Can.",
          everyMortageProfessional:
            "Every mortgage professional knows the market’s best rates every time they check their email. Only a few of them will give you that rate without making you work for it. Rawi Hammal’s here to change the industry for this very reason.",

          amortageatyourFinger:
            "  A Mortgage at Your Fingertips, Because It’s 2022.",
          whoHasTheLuxary:
            "Who has the luxury to take a week off to go mortgage shopping? You focus on the milestone here: purchasing a home. We’ll take care of the mortgage part.",

          itmorethanaMortage: " It’s More Than a Mortgage, It’s Your Home.",
          findingaforeverhome:
            "Finding a forever home is a great accomplishment. Let’s make it fun. Let’s keep it bright.",

          ourMission: "Our Mission",
          webelieve: "We believe ",
          peoplearebright: "people are bright.",
          RawiHammalisCanada:
            "Rawi Hammal is Canada’s leading digital mortgage lending platform, with a dedicated team of qualified mortgage experts supported by advanced technologies.",
          theCompanyIsOnmission:
            "The company is on a mission to offer a positive, empowering and transparent property financing experience, simplified from start to finish.",
          RawiHammalIsDelivering:
            "Rawi Hammal is delivering on this mission by offering Canadians a leading digital mortgage experience and by empowering partner mortgage lenders to improve and streamline their mortgage lending operations with the Rawi Hammal Mortgage Cloud.",
          RawiHammalTeam: "Rawi Hammal Team",

          Faq: "Frequently Asked Questions",

          whoAreThepeoplebehind: "Who am I? ",
          whileRawiHammalisCanadasfirstdigital:
            " I am Rawi Hammal, a mortgage specialist who can assist clients all across Canada.",

          howIsRawiHammalDifferent: "What services do I offer? ",
          therearemaindifferencesbetween:
            " I offer personalized mortgage solutions for first-time homebuyers, clients looking to refinance existing properties, and those interested in exploring their mortgage options.",

          canRawihammalHelpAnApplicant: "What is my objective? ",
          RawiHammalhasstrictlendingguidelines:
            "My objective is to provide the best possible service to my clients.",

          whatTypeOfPropertiesDoesRawiHammal:
            "How do I help clients navigate the mortgage process? ",
          canRawiHammalfinanceresidentialproperties:
            "With years of experience in the industry, I have developed a deep understanding of the complexities of the mortgage process. I work with my clients to understand their unique needs and goals, and develop customized mortgage solutions that meet their needs.",

          approchofcommunication: "What is my approach to communication? ",
          belivethatcommunication:
            "I believe that communication is key to a successful mortgage transaction. I keep my clients informed every step of the way, providing regular updates and answering any questions they may have.",

          contactme: "How can clients contact me? ",
          clientcancontact:
            "Clients can contact me through my website to schedule a consultation and start the journey towards achieving their mortgage goals.",

          firstName: "First Name",
          email: "Email",
          phone: "Phone",
          subject: "Subject",
          message: "Message",
          phone: "Phone",
          message: "Message",
          name: "Name",

          // q3

          theRefinancing:
            "Will you live in this property following the refinancing?",
          currentPropertyValue: "What is your current property value?",
          remainingMortgageBalance: "What is your remaining mortgage balance?",
          currentMortgageLender: "Who is your current mortgage lender?",
          withdrawFromYourProperty:
            "How much additional funds would you like to withdraw from your property?",

            termandcondition:"Terms & Conditions",
            privacypolicy:"Privacy Policy",
           

          // q4

          description: {
            part1:
              "Your client-focused specialist for all things home financing in Canada.",
            part2: "Learn React",
          },
        },
      },
      de: {
        translation: {
          // options
          yes: "Oui",
          no: "NON",

          notFoundProperty:
            "Si vous n'avez pas encore trouvé de propriété, entrez simplement le montant approximatif que vous recherchez.",
          basedOnyourTiminng:
            "En fonction de votre timing, nous vous guiderons vers la meilleure prochaine étape.",
          readOurPrivacyPolicy: "Lire notre politique de confidentialité",

          filterRates:
            "Nous filtrons les tarifs en fonction de votre situation exacte. Si vous ne vivez pas ici, nous supposerons qu'il s'agit d'un bien locatif.",

          sellYourProperty:
            "☝ Si vous deviez vendre votre propriété demain, quel juste prix en obtiendriez-vous ",
          smallerMortage:
            "☝ Plus le solde de votre prêt hypothécaire est petit par rapport à la valeur de votre propriété, meilleurs seront les taux. Nous calculerons ce rapport aux taux de filtrage qui vous seront présentés.",
          help: "Cela nous aidera à définir le meilleur moment pour garantir votre tarif.",

          maximumWithdraw:
            "Le solde de votre prêt hypothécaire plus vos fonds supplémentaires ne peuvent pas dépasser 80 % de la valeur de votre propriété. Le maximum que vous pouvez retirer est de 7 999 989 $.",

          houseOrCondo: "Maison ou condo",
          secondaryHomeOrCottage: "Résidence secondaire ou chalet",
          duplex: "Duplex",
          triplex: "Triplex",
          fourplex: "Quadruplex",
          commercial: "Commerciale",

          fullName: "Nom et prénom",
          email: "E-mail",
          phone: "Téléphone fixe",
          city: "Ville",
          state: "Province",
          zipCode: "Code postal",
          preferedLanguage: "Langue préférée",
          fewMoreDetails:
            "Nous avons besoin de quelques détails supplémentaires pour vous obtenir un tarif parfait:",
          mortageRequest: "Hypothèque demandée pour :",
          refinancing: "Refinancement",
          purchase: "Acheter",
          preapproval: "Préapprobation",
          NumberOfProperties: "Nombre de propriétés",
          NumberOfApplicants: "Nombre de candidats",
          joinrawihammal: "Rejoignez la liste VIP de Rawi Hammal",
          getaQuote: "Obtenir un devis",

          continue: "Continuez",
          back: "Dos",
          career: "Carrière",
          privacyPolicy: "politique de confidentialité",
          privacyChoices: "Choix de confidentialité",

          reviews: "Commentaires",

          testimonial: "Témoignage",
          peopleAreSaying: "Les gens disent",

          termandcondition:"Termes et Conditions",
          privacypolicy:"Politique de Confidentialité",

          getrateschangeupdates:
            " Obtenez des mises à jour sur les changements de taux, des conseils hypothécaires et des conseils d'experts",
          byclickingSignUp:
            "En cliquant sur S'inscrire, vous acceptez les <1> conditions générales </1> de Rawi Hammal. Vous pouvez vous désinscrire à n'importe quel moment.",
          submit: "Soumettre",

          canada: "Canada",
          england: "England",
          us: "US",
          french: "Français",
          english: "Anglais",
          selfEmployment: "Travail indépendant Non incorporé",
          selfEmploymentIncorporated: "Travail indépendant incorporé",
          employed: "Employée",
          retired: "À la retraite",
          floatingEmpType: "Type d'emploi",
          employmentType: "Type d'emploi",
          currentEstimatedPropertyValue:
            "Valeur actuelle estimée de la propriété",
          currentMortgageAmount: "Montant actuel de l'hypothèque",
          endOfCurrentTerm: "Fin du mandat en cours",
          requestedRefinanceAmount: "Montant de refinancement demandé",
          currentFI2: "FI actuel",
          IsThePropertyACodoPlexOrHome:
            "L'établissement est-il un : Codo, Plex ou Home",
          requestedAmount: "Quantité exigée",
          isThePropertyCondoPlexOrHome:
            "S'agit-il d'un condo, d'un plex ou d'une maison ?",
          conditionOfFinancing: "Condition de financement",
          preapprovalAmount: "Montant de la préapprobation",
          isThePropertyACondoPlexOrHome:
            "est-ce que la propriété est un condo, un plex ou une maison",

          offerToPurchase: "J'ai fait une offre d'achat",
          offerSoon: "Je prévois de faire une offre prochainement",
          getPreQualified: "Je veux être pré-qualifié",
          justCurious: "Je suis juste curieux",
          renewingYourMortgage: "Quand renouvelez-vous votre hypothèque?",

          in1To2Months: "dans 1 à 2 mois",
          in2To4Months: "dans 2 à 4 mois",
          inMoreThan4months: "dans plus de 4 mois",

          liveInThisNewProperty: "Allez-vous habiter la propriété?",
          typeOfProperty: "'De quel type de propriété s'agit-il?'",
          valueInPropety:
            "Quel est le prix d'achat envisagé pour la propriété?",
          yourDownPayment: "Quel est le montant de votre mise de fonds?",
          basicDetails: "Détails de base",
          foundAProperty: "Trouvé un bien",
          whosBehind: "Qui est derrière",

          diagramisalaunchpad:
            "Je suis Rawi Hammal, un spécialiste en prêts hypothécaires dévoué à offrir le meilleur service possible à des clients partout au Canada",
          withTheirlongTerm:
            "Avec des années d'expérience dans l'industrie, j'ai acquis une compréhension approfondie des complexités du processus hypothécaire, et je m'engage à aider mes clients à naviguer dans le paysage souvent déroutant des prêts hypothécaires.",
          diagramIsBacked:
            "Que vous soyez un acheteur d'une première maison, que vous cherchiez à refinancer une propriété existante, ou que vous souhaitiez explorer vos options hypothécaires, je suis là pour vous aider. Avec un accent mis sur le service personnalisé et l'attention aux détails, je travaille avec vous pour comprendre vos besoins et objectifs uniques, et développer une solution hypothécaire personnalisée qui répond à vos besoins.",
          portageFinance:
            "Je crois que la communication est la clé d'une transaction hypothécaire réussie. Je vous tiendrai informé à chaque étape du processus, en fournissant des mises à jour régulières et en répondant à toutes les questions que vous pourriez avoir.",
          soifyouare:
            "Donc, si vous avez besoin d'un spécialiste en prêts hypothécaires en qui vous pouvez avoir confiance, ne cherchez plus. Contactez-moi dès aujourd'hui pour planifier une consultation et commencer le voyage vers la réalisation de vos objectifs hypothécaires.",

          whychooseRawiHammal: "Pourquoi choisir Rawi Hammal",
          becauseWeOffer:
            "Parce que nous offrons le tarif plancher dès le départ. C'est l'achat le plus important de votre vie, pourquoi devriez-vous payer une prime pour cela ?",
          yesWemakeThem:
            "Oui, nous gagnons moins que la moyenne des courtiers ou des spécialistes en prêts hypothécaires, mais nous avons l'esprit tranquille en sachant que nous vous avons aidé à économiser des milliers de dollars sur votre prêt hypothécaire. Nous nous sentons plutôt bien à ce sujet",
          // q2
          followingheRenewal:
            "Allez-vous habiter la propriété suite au renouvellement?",
          propertyValue: "Quelle est la valeur de votre propriété actuelle?",
          mortgageBalance: "Quel est le solde de votre hypothèque?",

          tellus: "Dites-nous",
          yourgoal: "ton but",

          inputyour: "Entrez votre",
          propertyinformation: "Informations sur la propriété",

          getyour: "Obtenir votre",
          bestrate: "Meilleur taux",

          lowMortageRates: "Des solutions hypothécaires sur mesure pour vous ",
          ScheduleanAppointment: "Planifier un rendez-vous",
          howCanweHelp:
            "Comment pouvons-nous vous aider avec votre hypothèque?",

          newMortgage: "Nouvelle hypothèque",
          mortgageRenewal: "Renouvellement hypothécaire",
          refinanceMyMortgage: "Refinancer mon prêt hypothécaire",
          preApproval: "Pré-approbation",
          scheduleACall: "Programmer un appel",

          ineedapre:
            "J'ai besoin d'une pré-qualification, d'une preuve de financement pour mon offre ou une nouvelle hypothèque pour la propriété que j'achète.",
          iwanttorenew:
            "Je veux renouveler mon hypothèque ou comparer mon hypothèque actuelle offre du prêteur avec l'offre de nesto parce que mon hypothèque la date de renouvellement approche.",
          wanttorefinance:
            "Je veux refinancer pour réduire mes versements hypothécaires et augmenter mes liquidités, consolider mes dettes, accéder à mon fonds propres pour financer un investissement ou une dépense importante.",
          ineddaprequalification:
            "J'ai besoin d'une pré-qualification, d'une preuve de financement pour mon  offre ou une nouvelle hypothèque pour la propriété que j'achète.",
          on: "sur",

          quickLinks: "Liens rapides",
          contactUs: "Contactez-nous",
          aboutUs: "À propos de nous",
          faq: "FAQ",
          testimonialFooter: "Témoignage",
          address: "Adresse",
          newyork: "88 rue new york",
          contact: "Contactez",
          howRawiHammalWorks: "Comment fonctionne Rawi Hammal",

          alltheHelpWithouttheCommission: "Toute l'aide, sans la commission.",
          implyputoursalaried:
            "En termes simples, nos conseillers salariés sont récompensés en fonction de votre satisfaction. Nous sommes là pour vous aider à atteindre votre objectif et vous guider dans le monde compliqué du financement immobilier. #yesyoucan #empowermentisthenewsexy",

          theBestRates: "Les meilleurs tarifs, parce que nous le pouvons.",
          everyMortageProfessional:
            "Chaque professionnel du crédit hypothécaire connaît les meilleurs taux du marché chaque fois qu'il consulte ses e-mails. Seuls quelques-uns d'entre eux vous donneront ce taux sans vous faire travailler pour cela. Rawi Hammal est là pour changer l'industrie pour cette raison.",

          amortageatyourFinger:
            "Une hypothèque à portée de main, car nous sommes en 2022.",
          whoHasTheLuxary:
            "Qui a le luxe de prendre une semaine de congé pour faire des emplettes hypothécaires? Vous vous concentrez sur le jalon ici : l'achat d'une maison. Nous nous occupons de la partie hypothécaire.",

          itmorethanaMortage:
            "C'est plus qu'une hypothèque, c'est votre maison.",
          findingaforeverhome:
            "Trouver une maison pour toujours est une grande réussite. Rendons-le amusant. Gardons-le brillant.",

          ourMission: "Notre mission",
          webelieve: "Nous croyons",
          peoplearebright: "les gens sont brillants.",
          RawiHammalisCanada:
            "Rawi Hammal est la principale plateforme numérique de prêts hypothécaires au Canada, avec une équipe dédiée d'experts hypothécaires qualifiés soutenus par des technologies de pointe.",
          theCompanyIsOnmission:
            "La société a pour mission d'offrir une expérience de financement immobilier positive, responsabilisante et transparente, simplifiée du début à la fin.",
          RawiHammalIsDelivering:
            "Rawi Hammal remplit cette mission en offrant aux Canadiens une expérience hypothécaire numérique de premier plan et en permettant aux prêteurs hypothécaires partenaires d'améliorer et de rationaliser leurs opérations de prêt hypothécaire avec Rawi Hammal Mortgage Cloud.",
          RawiHammalTeam: "L'équipe de Rawi Hammal",

          // faq Start

          Faq: "Questions fréquemment posées",

          whoAreThepeoplebehind: "Qui suis-je ? ",
          whileRawiHammalisCanadasfirstdigital:
            "Je suis Rawi Hammal, un spécialiste en prêts hypothécaires qui peut aider des clients partout au Canada.",

          howIsRawiHammalDifferent: "Quels services est-ce que j'offre ? ",
          therearemaindifferencesbetween:
            "J'offre des solutions de prêts hypothécaires personnalisées pour les acheteurs de première maison, les clients qui cherchent à refinancer des propriétés existantes et ceux qui souhaitent explorer leurs options hypothécaires.",

          canRawihammalHelpAnApplicant: "Quel est mon objectif ? ",
          RawiHammalhasstrictlendingguidelines:
            "Mon objectif est de fournir le meilleur service possible à mes clients.",

          whatTypeOfPropertiesDoesRawiHammal:
            "Comment est-ce que j'aide les clients à naviguer dans le processus hypothécaire ? ",
          canRawiHammalfinanceresidentialproperties:
            "Avec des années d'expérience dans l'industrie, j'ai développé une compréhension approfondie des complexités du processus hypothécaire. Je travaille avec mes clients pour comprendre leurs besoins et objectifs uniques, et développe des solutions hypothécaires sur mesure qui répondent à leurs besoins.",

          approchofcommunication:
            ": Quelle est mon approche en matière de communication ? ",
          belivethatcommunication:
            "Je crois que la communication est la clé d'une transaction hypothécaire réussie. Je tiens mes clients informés à chaque étape du processus, en fournissant des mises à jour régulières et en répondant à toutes les questions qu'ils peuvent avoir.",

          contactme: "Comment les clients peuvent-ils me contacter ? ",
          clientcancontact:
            "Les clients peuvent me contacter via mon site web pour planifier une consultation et commencer leur parcours vers la réalisation de leurs objectifs hypothécaires.",

          // faq End

          // Contact Start

          letscontact: "Contactons",
          contactWithUs: "En contact avec nous",
          emergencyContact: "Personne à contacter en cas d'urgence",

          // Contact End

          // Testimonial Start

          JoinCanadiansthat:
            "Joignez-vous à plus de 210 000 Canadiens* qui nous ont fait confiance.",

          CheckoutRawiHammalreviews:
            "Consultez les avis de Rawi Hammal de personnes qui étaient à votre place et qui ont maintenant un taux bas qui leur convient.",

          realPeople: "Vrais gens,",
          realReviews: "de vrais avis",

          BeOneOfThem: "Soyez l'un d'entre eux",

          becomeAhappyRawiHammal: "Devenez un client satisfait de Rawi Hammal",

          connevtWith:
            "Connectez-vous avec plus de 250 experts hypothécaires agréés en qui vous pouvez avoir confiance",

          chatWithUs: "Discutez avec nous aujourd'hui",
          // Testimonial End
          yourmortgagesimplifiedSavetime: "Votre hypothèque simplifiée.",
          savebig: "Économisez gros.",
          saveTime: "Gagner du temps.",

          firstName: "Prénom",
          email: "E-mail",
          phone: "Téléphone",
          subject: "Sujette",
          message: "Message",
          phone: "Phone",

          message: "Message",
          name: "Nom",

          // q3

          theRefinancing:
            "Allez-vous habiter la propriété suite au refinancement?",
          currentPropertyValue:
            "Quelle est la valeur de votre propriété actuelle?",
          remainingMortgageBalance: "Quel est le solde de votre hypothèque?",
          currentMortgageLender: "Quel est votre prêteur hypothécaire ?",
          homeCost: "Combien coûtera votre maison?",
          withdrawFromYourProperty:
            "Combien de fonds supplémentaires aimeriez-vous retirer?",

          description: {
            part1:
              "Votre spécialiste axé sur le client pour tous les aspects du financement immobilier au Canada.",
            part2: "Lerne React",
          },

          question2: {
            part1: "Will you live in this property following the renewal?",
            part2: "What is your current property value?",
            part3: "What is your remaining mortgage balance?",
            part4: "Basic Details",
          },
          question3: {
            part1: "Will you live in this property following the refinancing?",
            part2: "What is your current property value?",
            part3: "What is your remaining mortgage balance?",
            part4: "Who is your current mortgage lender?",
            part5:
              "How much additional funds would you like to withdraw from your property?",
            part6: "Basic Details",
          },
          question4: {
            part1: "Allez-vous habiter la propriété?",
            part2: "'De quel type de propriété s'agit-il?'",
            part3: "Quel est le prix d'achat envisagé pour la propriété?",
            part4: "Quel est le montant de votre mise de fonds?",
            part5: "Détails de base",
          },
        },
      },
    },
  });

export default i18n;
