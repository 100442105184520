import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { removeChat } from "../../Redux/slices/chatSlice";
import BaseUrl from "../../BaseUrl";
import Pusher from "pusher-js";

const Items = ({ items }) => {
  const dispatch = useDispatch();
  const chatId = useSelector((state) => state?.chat?.chatId);
  const dataItems = useSelector((state) => state?.chat?.items);

  const [chatData, setChatData] = useState(dataItems);

  // console.log("chatId for message =========== ", chatId)

  useEffect(() => {
    const checkLastMessage = () => {
      if (items.length > 0) {
        const currentTime = items[1];
        const lastMessage = items[items.length - 1];
        const lastMessageTime = new Date(lastMessage?.created_at);
        const currentMessageTime = new Date(currentTime?.created_at);
        // 86400000
        console.log(
          "last",
          lastMessageTime,
          "current",
          currentMessageTime,
          currentMessageTime - lastMessageTime,
          currentMessageTime - lastMessageTime > 86400000
        );
        if (currentMessageTime - lastMessageTime > 86400000) {
          // Remove the last message if it's older than 1 minute
          dispatch(removeChat());
          window.localStorage.setItem("OTM", "");
          console.log("remove Items");
        } else {
          console.log("not match");
        }
      }
    };

    const timer = setInterval(checkLastMessage, 10000); // Check every minute

    return () => clearInterval(timer); // Cleanup the interval when component unmounts
  }, [items?.length]);

  const getWebMessages = () => {
    if (chatId) {
      BaseUrl.get(`get_web_messages/${chatId}`)
        .then((res) => {
          setChatData(res?.data?.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    //     getAllMessages();
    const pusher = new Pusher("e4ee6f0dc3f7eecb2c71", {
      cluster: "ap2",
    });
    const channel1 = pusher.subscribe("channel1");

    channel1.bind("event1", function (data) {
      console.log("data", data);
      if (!data?.ip_address && data?.status !== "seen") {
        // setitems((e) => [data, ...e]);
        console.log("data======================", data);
        // dispatch(saveMessage(data));
        getWebMessages();
      }
    });

    return () => {
      pusher.unsubscribe("my-channel");
    };
  }, []);

  useEffect(() => {
    getWebMessages();
  }, []);

  console.log("chatData ========= ", chatData);

  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          height: 300,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <InfiniteScroll
          dataLength={chatData.length}
          // next={fetchMoreData}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            overflow: "hidden",
          }}
          inverse={true}
          hasMore={true}
          //   loader={<h6 className="text-center">Loading...</h6>}
          scrollableTarget="scrollableDiv"
        >
          {Array.isArray(chatData) &&
            chatData?.map((item, index) => (
              <div key={index}>
                {item?.receiver_id !== "1" ? (
                  <div className="col-12">
                    <div className="row align-items-start mt-3">
                      <div className="col-auto">
                        <div
                          className="rounded-circle s-14 d-flex bg-chat text-light justify-content-center align-items-center border"
                          style={{ height: "40px", width: "40px" }}
                        >
                          RH
                        </div>
                        {/* <img className="bg-light rounded-3" src={user} alt="" width={50} /> */}
                      </div>
                      <div className="col ps-0">
                        <div className="fit-content shadow-sm s-12 bg-chat text-light p-2 rounded-2 border">
                          {/* <EmojiConverter text={item?.message} /> */}
                          {item?.message}
                        </div>
                        <p className=" mb-0 mt-1 s-10">
                          {moment(item?.created_at).format(
                            "MMM Do YY h:mm:ss a"
                          )}
                          {/* 23 August 08:30 AM */}
                        </p>
                      </div>
                      <div className="col-auto"></div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    <div className="row align-items-start mt-3">
                      <div className="col-auto"></div>

                      <div className="col pe-0">
                        <div className="fit-content  bg-light shadow-sm p-2 rounded-2 s-12 border ms-auto">
                          {item?.message}{" "}
                          {/* <em-emoji shortcodes=":+1::skin-tone-2:"></em-emoji> */}
                          {/* <Emoji emoji="smile" size={24} /> */}
                        </div>
                        <p className="s-10 mb-0 text-end mt-1">
                          {moment(item?.created_at).format(
                            "MMM Do YY h:mm:ss a"
                          )}
                        </p>
                      </div>
                      <div className="col-auto">
                        <div
                          className="rounded-circle s-14  d-flex bg-light text-dark justify-content-center align-items-center border"
                          style={{ height: "40px", width: "40px" }}
                        >
                          SS
                        </div>
                        {/* <img className="bg-light rounded-3" src={user} alt="" width={50} /> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default Items;
