import React, {useEffect, useState} from "react";
import { Form, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import BaseUrl from "../../BaseUrl";
import { Link } from "react-router-dom";

const NewsLetter = ({ link }) => {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [toggle, setToggle] = useState(false);
  const [error, setError] = useState(null);

  const [validated, setValidated] = useState(false);
  console.log({ validated });

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      let data = new FormData();
      data.append("name", form?.name.value);
      data.append("email", form?.email.value);

      console.log(
        "name",
        form?.name.value,
        "email",
        form?.email.value,
        "toggle",
        form?.formBasicCheckbox.checked
      );
      BaseUrl.post("mortgagenewsletter", data)
        .then(function (response) {
          console.log("response", response);
          if (response?.data?.status === 200) {
            setName("");
            setEmail("");
            setToggle(false);
            setValidated(false);
            setError(null);
            toast.success(`${response?.data?.Success} successfully`, {id: 'newsLetterFooterSuccess'});
          } else {
            toast.success(`${response?.data?.warning}`, {id: 'newsLetterFooterWarning'});
            setError(response?.data?.warning);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };



  return (
    <>
      <div className="col-md-12 mt-3">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-5">
              <Form.Group className="mb-3" controlId="name">
                <Form.Control
                  required
                  className="p-3 rounded-0"
                  type="text"
                  placeholder={t("firstName")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </div>

            <div className="col-md-5">
              <Form.Group className="mb-3 rounded-0" controlId="email">
                <Form.Control
                  required
                  className="p-3 rounded-0"
                  type="email"
                  placeholder={t("email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.email)}
                />
                {error ? (
                  <Form.Text className="text-danger">{error}</Form.Text>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>

            <div className="col-md-2">
              <Button className="p-3 rounded-0 w-100" type="submit">
                {t("submit")}
              </Button>
            </div>

            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                {/* t("byclickingSignUp") */}

                <Form.Check
                  required
                  type="checkbox"
                  className="text-white"
                  checked={toggle}
                  onChange={() => setToggle(!toggle)}
                  label={link}
                  feedbackType="invalid"
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>{" "}
    </>
  );
};

export default NewsLetter;
