import React, {useEffect} from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import BaseUrl from "../../BaseUrl";

const CoockiesModal = () => {
    const [show, setShow] = useState(true);

    const handleSave = () => {
        window.localStorage.setItem('cookiesPrivacy', 'true');
        setShow(false);
        getLocation()
    }
    const handleClose = () => {
        window.localStorage.setItem('cookiesPrivacy', 'false');
        setShow(false);
    }

    const getLocation = async () => {
        try {
            const ip = await fetch('https://geolocation-db.com/json/')
            const ipData = await ip.json()
            if(ipData?.IPv4) {
                const location = await fetch(`https://ip-get-geolocation.com/api/json/${ipData?.IPv4}`)
                const locationData = await location.json()
                if(locationData?.status === 'success') {
                    window.localStorage.setItem('ip', `${ipData?.IPv4}`);
                    const fullData = {
                        ...ipData,
                        ...locationData,
                    }
                    submitLocation(fullData)
                }
            }
        } catch (e) {
            console.log('e', e.message)
        }
    }

    const submitLocation = async (data) => {
        const form = new FormData();
        form.append('ip_address', data?.IPv4)
        form.append('country', data?.country_name)
        form.append('state', data?.state)
        form.append('city', data?.city)
        form.append('lat', data?.lat)
        form.append('lon', data?.lon)
        form.append('zip', data?.zip)
        form.append('timezone', data?.timezone)

        BaseUrl.post(`trackingIp`, form)
            .then((res) => {
                if(res?.data?.status === 200) {
                    window.localStorage.setItem('trackingId', `${res?.data?.tracking_id}`);
                }
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    useEffect(() => {
        window.localStorage.getItem('cookiesPrivacy') && setShow(false);
    }, [])


    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                {/* <Modal.Header closeButton className='border-0'> */}
                <Modal.Header className='border-0'>
                    <Modal.Title >We respect your privacy </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    We use cookies to operate this website, improve usablity, personalize yor experience,
                    and improve our marketing. Your Privacy is important to us, and we will never sell your data.
                    {" "}

                    <Link to={"/privacy-policy"} className='text-decoration-none'>
                        Privacy Policy
                    </Link>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="primary" className='w-100' onClick={handleSave}>
                        Accept Cookies
                    </Button>
                    <Button variant="light" className='w-100' onClick={handleClose}>Reject All</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CoockiesModal