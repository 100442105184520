import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  chatId: null,
};

export const formSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    saveMessage: (state, action) => {
      console.log('a========', action?.payload)
      console.log('b========', state.items)
      state.items = [action?.payload, ...state.items];
    },
    saveAllMessage: (state, action) => {
      state.items = action?.payload;
    },
    removeChat: (state) => {
      state.items = [];
    },
    saveChatId: (state, { payload }) => {
      state.chatId = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveMessage, saveAllMessage, removeChat, saveChatId } =
  formSlice.actions;

export default formSlice.reducer;
