import React, { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { BsFillChatDotsFill } from "react-icons/bs";
import { ImAttachment } from "react-icons/im";
import { AiOutlineSend } from "react-icons/ai";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { useTranslation } from "react-i18next";
// import Collapse from 'react-bootstrap/Collapse';
import logo2 from "../../Assets/Images/36.png";
import NewsLetter from "./NewsLetter";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Api/ApiConstat";
import BaseUrl from "../../BaseUrl";
import CoockiesModal from "../Home/CoockiesModal";
import { useRef } from "react";
import { GiCancel } from "react-icons/gi";
import user from "../../Assets/Images/default-profile.svg";
import ChatBox from "./ChatBox";

const Layout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const location = useLocation();
  const [socialLink, setSocialLink] = useState(null);
  const [contactLink, setContactLink] = useState(null);
  const [logo, setLogo] = useState(null);
  const [locationHistory, setLocationHistory] = useState([]);
  const [newsLetterContent, setNewsLetterContent] = useState(null);

  const handleLanguage = (event) => {
    if (event.target.checked) {
      i18n.changeLanguage("de");
      document.documentElement.setAttribute("lang", "de");
    } else {
      i18n.changeLanguage("en");
      document.documentElement.setAttribute("lang", "eng");
    }
  };

  const getSocialLinks = () => {
    BaseUrl.get(`get_social_links`)
      .then((res) => {
        setSocialLink(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getLogo = () => {
    BaseUrl.get(`get_logo`)
      .then((res) => {
        setLogo(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    BaseUrl.post("get_footer_newsletter_content")
      .then((res) => {
        setNewsLetterContent(res?.data?.data);
      })
      .catch((err) => {
        console.log("e", err);
      });
  }, []);

  const getLocation = async (location) => {
    switch (location) {
      case "/":
        return "Home";
      case "/testimonial":
        return "Testimonial";
      case "/about":
        return "About";
      case "/faq":
        return "FAQ";
      case "/contact-us":
        return "Contact Us";
      case "/privacy-policy":
        return "Privacy Policy";
      case "/term-and-condition":
        return "Term and Condition";
      case "/question-answer":
        return "New Mortgage";
      case "/question-answer2":
        return "Mortgage Renewal";
      case "/question-answer3":
        return "Refinance My Mortgage";
      case "/question-answer4":
        return "Schedule a Call";
      case "/calendli":
        return "New Mortgage Calendar";
      case "/calendli2":
        return "Mortgage Renewal Calendar";
      case "/calendli3":
        return "Refinance My Mortgage Calendar";
      case "/calendli4":
        return "Schedule a Call Calendar";
      case "/getaquote":
        return "Get a Quote";
      default:
        return "";
    }
  };
  const submitInteraction = async (loc) => {
    const location = await getLocation(loc);
    let form = new FormData();
    form.append("tracking_id", window.localStorage.getItem("trackingId"));
    form.append("page_path", location);

    BaseUrl.post(`trackingPage`, form)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getLogo();
    getSocialLinks();
  }, []);

  useEffect(() => {
    if (
      window.localStorage.getItem("cookiesPrivacy") === "true" &&
      window.localStorage.getItem("trackingId")
    ) {
      submitInteraction(location.pathname);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   const handleTabClose = event => {
  //     event.preventDefault();

  //     console.log('beforeunload event triggered');

  //     return (event.returnValue =
  //         'Are you sure you want to exit?');
  //   };

  //   window.addEventListener('beforeunload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);

  return (
    <>
      <CoockiesModal />
      <div className="position-relative chat">
        <ChatBox />

        <section
          className={`navBar ${
            location?.pathname == "/emial-unsubscribe" ? "d-none" : "d-block"
          }`}
        >
          <Navbar sticky="top" bg="white" expand="">
            <Container fluid>
              <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                  <div className="col-4 col-lg-auto">
                    <Link
                      onClick={() => setToggle(false)}
                      className="navbar-brand fw-600"
                      to="/"
                    >
                      {logo?.image ? (
                        <img
                          src={logo?.image}
                          className="logo-width"
                          alt="logo"
                        />
                      ) : (
                        ""
                      )}
                    </Link>
                  </div>

                  <div className="col-lg-7 col-8">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-auto">
                        <a
                          href={`tel: ${socialLink?.header_phonenumber}`}
                          className="text-theme h5 m-0 text-decoration-none d-none d-lg-block"
                        >
                          <i className="fa-solid fa-phone-volume me-2 fa-lg" />
                          {socialLink?.header_phonenumber}
                        </a>
                      </div>

                      <div className="col-auto">
                        <Link to="/getaquote" className="text-decoration-none">
                          <button
                            className="btn btn-dark rounded-pill fw-600 px-3 d-none d-lg-block"
                            role="button"
                          >
                            {t("getaQuote")}
                          </button>
                        </Link>
                      </div>

                      {/* <div className="col-auto">
                      <ButtonGroup>
                        {["English", "French"].map((radio, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            className="fw-600"
                            variant={"outline-dark"}
                            name="radio"
                            value={radio}
                            checked={radioValue === radio}
                            onChange={(e) =>
                              setRadioValue(e.currentTarget.value)
                            }
                          >
                            {radio}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </div> */}

                      <div className="col-auto">
                        <div className="switch">
                          <input
                            id="language-toggle"
                            className="check-toggle check-toggle-round-flat"
                            type="checkbox"
                            onClick={handleLanguage}
                          />
                          <label htmlFor="language-toggle"></label>
                          <span className="on">EN</span>
                          <span className="off">FR</span>
                        </div>
                      </div>

                      <div className="col-auto">
                        <Navbar.Toggle
                          onClick={() => setToggle(!toggle)}
                          className="p-0"
                          aria-controls="basic-navbar-nav"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Offcanvas
                show={toggle}
                onHide={() => setToggle(!toggle)}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title classname="fw-700 mb-0">
                    Menu
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="overflow-hidden">
                    <Nav className="flex-wrap flex-column">
                      <h5 className="text-theme m-0 border-bottom nav-link d-block d-lg-none">
                        <i className="fa-solid fa-phone-volume me-3" />
                        (514) 473-3310
                      </h5>

                      <Link
                        to="/question-answer"
                        className="text-decoration-none"
                      >
                        <button
                          className="btn-theme rounded-pill border-bottom mt-3 d-block d-lg-none"
                          type=""
                        >
                          {t("getaQuote")}
                        </button>
                      </Link>

                      <Link
                        to="about"
                        className="border-bottom fw-700 nav-link "
                        onClick={() => setToggle(false)}
                      >
                        {t("aboutUs")}
                      </Link>

                      <Link
                        to="faq"
                        className="border-bottom fw-700 nav-link text-uppercase"
                        onClick={() => setToggle(false)}
                      >
                        {t("faq")}
                      </Link>

                      <Link
                        to="contact-us"
                        className="border-bottom fw-700 nav-link"
                        onClick={() => setToggle(false)}
                      >
                        {t("contact")}
                      </Link>

                      <Link
                        to="privacy-policy"
                        className="border-bottom fw-700 nav-link"
                        onClick={() => setToggle(false)}
                      >
                        {t("privacypolicy")}
                      </Link>

                      <Link
                        to="term-and-condition"
                        className="border-bottom fw-700 nav-link"
                        onClick={() => setToggle(false)}
                      >
                        {t("termandcondition")}
                      </Link>

                      {/* <Link
                      to="testimonial"
                      className="border-bottom fw-700 nav-link"
                      onClick={() => setToggle(false)}
                    >
                      {t("testimonial")}
                    </Link> */}
                    </Nav>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </Container>
          </Navbar>
        </section>

        <div
          className={` ${
            location?.pathname == "/emial-unsubscribe" ? "mt-0" : "outlet-style"
          }`}
        >
          <Outlet />
        </div>

        <section className="footer border-top">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-capitalize text-center pt-5 text-white fw-700">
                  {document
                    .getElementsByTagName("html")[0]
                    .getAttribute("lang") == "eng"
                    ? newsLetterContent?.heading_english
                    : newsLetterContent?.heading_french}
                </h1>

                <h5 className="text-center text-white pt-4">
                  {document
                    .getElementsByTagName("html")[0]
                    .getAttribute("lang") == "eng"
                    ? newsLetterContent?.sub_heading_english
                    : newsLetterContent?.sub_heading_french}
                </h5>
              </div>

              <NewsLetter
                link={
                  document
                    .getElementsByTagName("html")[0]
                    .getAttribute("lang") == "eng"
                    ? newsLetterContent?.click_line_english
                    : newsLetterContent?.click_line_french
                }
              />

              <div className="col-md-12 mt-5">
                <div className="border-bottom border-3" />
              </div>
            </div>
          </div>

          <div className="container mt-5">
            <div className="row g-3 justify-content-between">
              <div className="col-lg col-md-6 col-12">
                <Link
                  onClick={() => setToggle(false)}
                  className="text-decoration-none"
                  to="/"
                >
                  <h3 className="text-white">{socialLink?.footer_heading}</h3>
                </Link>
                <p className="text-white">
                  {/*{t("yourmortgagesimplifiedSavetime")}*/}
                  {socialLink?.footer_tag_line}
                  {/* <br /> {t("savebig")} */}
                  {/* <br /> {t("saveTime")} */}
                </p>

                <div className="row g-3 mb-4">
                  <div className="col-auto">
                    <a
                      href={socialLink?.twitter}
                      className="fa-brands fa-twitter text-decoration-none text-white s-20"
                      target="_blank"
                    />
                  </div>
                  <div className="col-auto">
                    <a
                      href={socialLink?.facebook}
                      className="fa-brands fa-facebook-f text-decoration-none text-white s-20"
                      target="_blank"
                    />
                  </div>
                  <div className="col-auto">
                    <a
                      href={socialLink?.instagram}
                      className="fa-brands fa-instagram text-decoration-none text-white s-20"
                      target="_blank"
                    />
                  </div>
                  <div className="col-auto">
                    <a
                      href={socialLink?.linkedin}
                      className="fa-brands fa-linkedin-in text-decoration-none text-white s-20"
                      target="_blank"
                    />
                  </div>
                  <div className="col-auto">
                    <a
                      href={socialLink?.whatsapp}
                      className="fa-brands fa-whatsapp text-decoration-none text-white s-20"
                      target="_blank"
                    />
                  </div>

                  <div className="col-auto">
                    <a
                      href={socialLink?.youtube}
                      className="fa-brands fa-youtube text-decoration-none text-white s-20"
                      target="_blank"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 col-12">
                <h5 className="text-white">{t("quickLinks")}</h5>

                <ul className="">
                  <li>
                    <Link
                      to="/contact-us"
                      className="text-white text-decoration-none"
                    >
                      {t("contactUs")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/about"
                      className="text-white text-decoration-none"
                    >
                      {t("aboutUs")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/faq" className="text-white text-decoration-none">
                      {t("faq")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/privacy-policy"
                      className="text-white text-decoration-none"
                    >
                      {t("privacypolicy")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/term-and-condition"
                      className="text-white text-decoration-none"
                    >
                      {t("termandcondition")}
                    </Link>
                  </li>

                  {/*<li>
                  <Link
                    to="/getaquote"
                    className="text-white text-decoration-none"
                  >
                    Get a Quote
                  </Link>
                </li>*/}
                </ul>
              </div>

              <div className="col-lg-auto d-none">
                <h5 className="text-white">{t("aboutUs")}</h5>

                <ul>
                  <li>
                    <a href="" className="text-white text-decoration-none">
                      {t("contact")}
                      Contact
                    </a>
                  </li>

                  <li>
                    <a href="" className="text-white text-decoration-none">
                      {t("career")}
                    </a>
                  </li>

                  <li>
                    <a href="" className="text-white text-decoration-none">
                      {t("privacyPolicy")}
                    </a>
                  </li>

                  <li>
                    <a href="" className="text-white text-decoration-none">
                      {t("privacyChoices")}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg col-md-6 col-12">
                <h5 className="text-white">{t("address")}</h5>

                <ul>
                  <li>
                    <a
                      href={`tel: ${socialLink?.footer_phonenumber}`}
                      className="text-white text-decoration-none"
                    >
                      <i className="fa-solid fa-phone-volume me-3" />
                      {socialLink?.footer_phonenumber}
                    </a>
                  </li>

                  <li>
                    <a
                      href={`mailto: ${socialLink?.email}`}
                      className="text-white text-decoration-none"
                    >
                      <i className="fa-solid fa-envelope me-3"></i>
                      {socialLink?.email}
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Rawi+Hammal+hypoth%C3%A8que+%2F+mortgage/@45.564514,-73.655599,15z/data=!4m6!3m5!1s0x4cc9193683de582f:0x7b3e7e8620a3a773!8m2!3d45.5641539!4d-73.6557497!16s%2Fg%2F11fsn6nvh6?hl=en&entry=ttu"
                      className="text-white text-decoration-none"
                    >
                      <i className="fa-solid fa-location-dot me-3" />
                      {socialLink?.address}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.3125056547087!2d-73.6557497!3d45.5641539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9193683de582f%3A0x7b3e7e8620a3a773!2sRawi%20Hammal%20hypoth%C3%A8que%20%2F%20mortgage!5e0!3m2!1sen!2s!4v1673696784482!5m2!1sen!2s"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>

                {/* <iframe
                src="https://www.google.com/maps/place/Rawi+Hammal+hypoth%C3%A8que+%2F+mortgage/@45.5641576,-73.6579384,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc9193683de582f:0x7b3e7e8620a3a773!8m2!3d45.5641539!4d-73.6557497"
                width="100%" height="200" style={{ border: 0 }} allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              /> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Layout;
