import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { persistor, store } from "./Redux/store";
import reportWebVitals from "./reportWebVitals";
// import i18n (needs to be bundled ;))
import HttpsRedirect from "react-https-redirect";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HttpsRedirect>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <React.Suspense>
          <App />
        </React.Suspense>
      </PersistGate>
    </Provider>
  </HttpsRedirect>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
