import React from "react";
import "./App.css";
import AppRouting from "./Routes/AppRouting";
import {Toaster} from "react-hot-toast";

function App() {
    function getCookie(name) {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName) {
                return decodeURIComponent(cookieValue);
            }
        }
        return null;
    }

    const myCookieValue = getCookie();
    console.log('Cookie Value:', myCookieValue);

    return (
        <>
            <Toaster position="top-center" reverseOrder={false}/>
            <AppRouting/>
        </>
    );
}

export default App;
