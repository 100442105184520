import React, { useEffect, useRef, useState } from "react";
import { GiCancel } from "react-icons/gi";
import { ImAttachment } from "react-icons/im";
import { AiOutlineClose, AiOutlineSend } from "react-icons/ai";
import { BsFillChatDotsFill, BsFillTelephoneFill } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { BiSolidPhone } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import BaseUrl from "../../BaseUrl";
import moment from "moment/moment";
import Pusher from "pusher-js";
import { useDispatch, useSelector } from "react-redux";
import {
  removeChat,
  saveChatId,
  saveMessage,
} from "../../Redux/slices/chatSlice";
import { Emoji } from "emoji-mart";
import Items from "./Items";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Card, Form } from "react-bootstrap";
import ChatSection from "./ChatSection";

const ChatBox = () => {
  const items = useSelector((state) => state?.chat?.items);
  const chatId = useSelector((state) => state?.chat?.chatId);

  // console.log("chatId =========== ", chatId)

  const dispatch = useDispatch();
  const [chatBox, setChatBox] = useState(false);
  // const [items, setitems] = useState([]);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [startTime, setStartTime] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [socialLink, setSocialLink] = useState(null);
  const [chatOptions, setChatOptions] = useState(false);
  const [newChatId, setNewChatId] = useState();
  const [showEmailBox, setShowEmailBox] = useState(true);

  const fileInputRef = useRef(null);

  const EmojiConverter = ({ text }) => {
    // Replace text-based emoji representations with actual emojis
    const emojiText = text.replace(/:[\w]+:/g, (match) => {
      const emoji = match.substring(1, match.length - 1); // Remove colons
      return <Emoji emoji={emoji} size={20} />;
    });

    return <div>{emojiText}</div>;
  };

  const handleImageClick = () => {
    // Trigger a click event on the hidden file input
    fileInputRef.current.click();
  };

  const handleCloseChat = () => {
    if (chatId) {
      BaseUrl.get(`status_offline/${chatId}`)
        .then((res) => {
          console.log("res===,,", res);
          setChatBox(false);
          // window.localStorage.setItem("chatId", `${res?.data?.id}`);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setChatBox(false);
    }
  };

  const submitLocation = async (data) => {
    const form = new FormData();
    form.append("ip_address", data);
    form.append("email", email);
    form.append("name", name)

    BaseUrl.post(`get_chat_user_location`, form)
      .then((res) => {
        setIsFetching(false);
        setShowModal(false);
        setChatBox(true);
        const chatId = res?.data?.id;
        if (typeof chatId == "object") {
          dispatch(saveChatId(chatId?.id));
          window.localStorage.setItem("chatId", `${chatId?.id}`);
        } else {
          dispatch(saveChatId(chatId));
          window.localStorage.setItem("chatId", `${chatId}`);
        }
        console.log("chatId", chatId);

        // console.log('YYYYYchatId===', chatId?.id)
        // setNewChatId(res?.data?.id)

        setShowEmailBox(true);
      })
      .catch((err) => {
        setIsFetching(false);
        console.log("err", err);
      });
  };

  const handleOnlineStatus = async (id) => {
    const form = new FormData();
    form.append("id", id);

    BaseUrl.post(`status_online_user`, form)
      .then((res) => {
        console.log("res?.data?.id", res?.data?.data);
        // setIsFetching(false);
        // setShowModal(false);
        // setChatBox(true);
        dispatch(saveChatId(res?.data?.data?.id));
        const chatId = res?.data?.data;

        // window.localStorage.setItem("chatId", `${res?.data?.id}`);
        window.localStorage.setItem("chatId", `${chatId?.id}`);
      })
      .catch((err) => {
        // setIsFetching(false);
        console.log("err", err);
      });
  };

  const getLocation = async (event) => {
    event.preventDefault();
    setIsFetching(true);
    try {
      const ip = await fetch("https://geolocation-db.com/json/");
      const ipData = await ip.json();
      if (ipData?.IPv4) {
        submitLocation(ipData?.IPv4);
      }
    } catch (e) {
      console.log("e", e.message);
      setIsFetching(false);
    }
  };

  const fetchMoreData = () => {
    // setTimeout(() => {
    //   setitems(items.concat(Array.from({ length: 10 })));
    // }, 1500);
  };

  const handleSendMessage = () => {
    var newId = chatId && true;

    if (newId) {
      setIsFetching(true);
      const id = window.localStorage.getItem("chatId");
      const form = new FormData();
      form.append("user_id", parseInt(id));
      form.append("sender_id", parseInt(id));
      form.append("receiver_id", 1);
      form.append("message", message);

      // console.log("msg Form ====== ", form)

      BaseUrl.post(`admin_message_send`, form)
        .then((res) => {
          // return console.log("status", res?.status)
          if (res?.status == 200 || res?.status == "success") {
            setIsFetching(false);
            setMessage("");
            //   setitems((item) => [res?.data?.data, ...item]);
          }
          console.log("res msg ", res);
        })
        .catch((err) => {
          setIsFetching(false);
          console.log("err", err);
        });
    } else {
      newId = false;
    }

    setShowEmailBox(newId);
  };

  const handleStatus = () => {
    const id = window.localStorage.getItem("chatId");
    const form = new FormData();
    form.append("user_id", parseInt(id));

    BaseUrl.post(`chat_seen_status`, form)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleOneTimeMessage = () => {
    console.log("sfsf", window.localStorage.getItem("OTM"));
    if (
      window.localStorage.getItem("OTM") === "" ||
      window.localStorage.getItem("OTM") === "undefined" ||
      window.localStorage.getItem("OTM") === null
    )
      BaseUrl.get(`auto_message_send`)
        .then((res) => {
          console.log("res==res", res);
          if (res?.data?.status === "successfull") {
            const response = {};
            window.localStorage.setItem("OTM", res?.data?.data);
            response["receiver_id"] = "2";
            response["message"] = window.localStorage.getItem("OTM");
            response["created_at"] = res?.data?.created_at;
            setMessage("");
            setTimeout(() => {
              dispatch(saveMessage(response));
            }, 3000);
            // setitems((item) => [res?.data?.data, ...item]);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
  };

  const getSocialLinks = () => {
    BaseUrl.get(`get_social_links`)
      .then((res) => {
        setSocialLink(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    handleOneTimeMessage();
  }, []);

  useEffect(() => {
    if (chatId) {
      handleOnlineStatus(chatId);
    }
  }, []);

  useEffect(() => {
    // getLogo();
    getSocialLinks();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setChatOptions(true);
      setChatBox(true);
    }, 10000);
  }, []);

  return (
    <>
      <div
        className="position-fixed p-3 bottom-0 end-0"
        style={{ zIndex: "9999" }}
      >
        {chatBox ? (
          <>
            <ChatSection
              handleCloseChat={handleCloseChat}
              handleSendMessage={handleSendMessage}
              handleStatus={handleStatus}
              getLocation={getLocation}
              isFetching={isFetching}
              email={email}
              setEmail={setEmail}
              name={name}
              setName={setName}
              message={message}
              setMessage={setMessage}
              showEmailBox={showEmailBox}
            />
          </>
        ) : (
          <></>
        )}

        {chatBox ? (
          <></>
        ) : (
          <>
            {chatOptions === true && (
              <>
                <button
                  onClick={() => setChatBox(true)}
                  className="ms-auto mt-2 btn btn-light shadow justify-content-center align-items-center rounded-circle d-flex"
                  style={{ height: "52px", width: "52px" }}
                >
                  <BsFillChatDotsFill className="text-success" size={32} />
                </button>

                <a
                  href={`tel: ${socialLink?.header_phonenumber}`}
                  className="ms-auto mt-2 btn btn-primary shadow text-light justify-content-center align-items-center rounded-circle d-flex p-2 "
                  style={{ height: "48px", width: "48px" }}
                >
                  <BiSolidPhone className="text-white" size={42} />
                </a>

                <a
                  href={socialLink?.whatsapp}
                  className="ms-auto mt-2 btn text-light shadow justify-content-center align-items-center rounded-circle d-flex p-2"
                  style={{
                    height: "48px",
                    width: "48px",
                    backgroundColor: "#25D366",
                  }}
                  target="_blank"
                >
                  <IoLogoWhatsapp className="text-white" size={42} />
                </a>
              </>
            )}

            {!chatOptions && <div className="pulsating-circle"></div>}

            <div className={`${!chatOptions ? "pulse" : " "} rounded-circle`}>
              <button
                onClick={() => setChatOptions(!chatOptions)}
                className="btn-shake ms-auto p-0 mt-2 btn btn-dark shadow justify-content-center align-items-center rounded-circle d-flex"
                style={{ height: "52px", width: "52px" }}
              >
                {chatOptions ? (
                  <IoCloseSharp size={30} />
                ) : (
                  <FaBars size={25} />
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ChatBox;
